import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import axios from "../../../../axios/osint.js";
import DomainSection from "@/components/domain-section";
import DomainCompanySection from "@/components/domain-company-section";
import DomainLinkedInUserDetails from "@/components/domain-linkedin-user-details";
import DomainCompanyDetailsSection from "@/components/domain-company-details-section";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {setUsernamesDropdownData, convertToCSV, csvFileName, getJurisdictionName} from "../../../../utils/functions";
import JsonCSV from "vue-json-csv";
import toolsHelper from "@/mixins/toolsHelper";
import {monitoringSearchData} from "@/utils/monitoringTools.js";
import toolsDisclaimer from "@/components/tools-disclaimer";
import neoAnalyse from "@/components/analyse";

export default {
    name: "company-domain",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "analysis-card": analysisCard,
        "domain-section": DomainSection,
        "domain-company-section": DomainCompanySection,
        "domain-linkedin-user-details": DomainLinkedInUserDetails,
        "domain-company-details-section": DomainCompanyDetailsSection,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "download-csv": JsonCSV,
        toolsDisclaimer,
        neoAnalyse,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    mixins: [toolsHelper],
    data() {
        return {
            triggerRoute: true,
            domainData: null,
            estimatedDomainAge: null,
            domainCompanyDetails: null,
            domainLinkedInUserDetails: [],
            domainCompanyDetailsSearch: [],
            showResult: false,
            showDomainResult: false,
            loaderController: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
            case_id: "",
            searchKeyword: "",
            selectedTab: "domain",
            inputValue: {
                text: "Company Name",
                value: "name",
            },
            inputOptions: [
                {
                    group: "Field Type",
                    categories: [
                        {
                            text: "Company Name",
                            value: "name",
                        },
                        {
                            text: "Domain",
                            value: "domain",
                        },
                    ],
                },
            ],
            tabs: [
                {
                    name: "Domain",
                    value: "domain",
                },
                {
                    name: "Company",
                    value: "company",
                },
                {
                    name: "Employees",
                    value: "employees",
                },
                {
                    name: "Company Details",
                    value: "company_details",
                },
            ],
            domainList: [],
            nameList: [],
            selectedItem: null,
            searchCompanyDetails: "",
            searchEmployees: "",
            graphData: null,
            selectVal: "",
            closed: false,
            searchedClicked: false,
            tabName: "",
            monitoringDomainData: [],
            monitoringDomainCompanyDetails: [],
            isMonitoringTab: false,
            request_id: null,
            domainNameResults: [],
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseDomains", "getAllMonitoringData", "getMonitoringDateFilter", "getSelectedToolQuery"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        query() {
            return this.selectedItem?.value;
        },

        results() {
            return this.selectedTab.replace("_", "");
        },

        csvData() {
            if (this.selectedTab === "domain" && this.domainData) {
                let data = [];
                data.push(this.domainData);
                return this.convertToCSV(data);
            } else if (this.selectedTab === "company" && this.domainCompanyDetails) {
                let data = [];
                data.push(this.domainCompanyDetails);
                return this.convertToCSV(data);
            } else if (this.selectedTab === "employees" && this.domainLinkedInUserDetails) {
                return this.convertToCSV(this.domainLinkedInUserDetails);
            } else if (this.selectedTab === "company_details" && this.domainCompanyDetailsSearch) {
                return this.convertToCSV(this.fetchDomainCompanyDetailsSearch.map((item) => item.company));
            }
        },

        csvFields() {
            if (this.selectedTab === "domain" && this.domainData) {
                return ["title", "creation_date", "ip", "age", "registrant_name", "registrant_org", "registrant_email", "registrant_address", "registrant_city", "registrant_state", "registrant_country", "registrant_phone", "registrant_fax", "other_domains", "whois"];
            } else if (this.selectedTab === "company" && this.domainCompanyDetails) {
                return ["name", "category", "logo", "foundedYear", "location", "site", "timeZone", "tags", "description", "facebook", "linkedin", "twitter", "metrics", "domainAliases"];
            } else if (this.selectedTab === "employees" && this.domainLinkedInUserDetails) {
                return ["name", "current_title", "current_employer", "location", "linkedin_url", "profile_pic"];
            } else if (this.selectedTab === "company_details" && this.domainCompanyDetailsSearch) {
                return ["name", "company_number", "company_type", "jurisdiction_code", "current_status", "registry_url", "opencorporates_url"];
            }
        },

        fetchDomainCompanyDetailsSearch() {
            const data = this.domainCompanyDetailsSearch.filter(
                (n) =>
                    n.company?.name?.toLowerCase().includes(this.searchCompanyDetails.toLowerCase()) ||
                    n.company?.company_number?.toLowerCase().includes(this.searchCompanyDetails.toLowerCase()) ||
                    n.company?.company_type?.toLowerCase().includes(this.searchCompanyDetails.toLowerCase()) ||
                    this.getJurisdictionDisplayName(n.company.jurisdiction_code)
                        ?.toLowerCase()
                        .includes(this.searchCompanyDetails.toLowerCase())
            );
            return data;
        },
        fetchDomainLinkedInUserDetails() {
            const data = this.domainLinkedInUserDetails.filter((n) => n.name?.toLowerCase().includes(this.searchEmployees.toLowerCase()) || n.current_title?.toLowerCase().includes(this.searchEmployees.toLowerCase()) || n.current_employer?.toLowerCase().includes(this.searchEmployees.toLowerCase()) || n.location?.toLowerCase().includes(this.searchEmployees.toLowerCase()));
            return data;
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        this.isMonitoringTab = this.$route.name === "monitoring";
        const dataRes = this.getToolsData(this.tabName, "company-domain");
        this.getToolData();
        if (dataRes) this.redirectToQuery(dataRes);
    },
    beforeDestroy() {
        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "company-domain"});
        this.setToolsData(this.tabName, "company-domain");
    },
    watch: {
        selectedTab: {
            handler: function(newSelectedTab) {
                if (newSelectedTab === "company") {
                    this.setLocationOnMap();
                }
            },
        },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAnalysisDropdownData", "getAllCaseDomains"]),

        async getToolData() {
            this.domainList = await this.getDropdownData("domain");
            this.nameList = await this.getDropdownData("company_name");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            if (list === "nameList") return [{group: "Company Name", categories: this.nameList}];
            else
                return [
                    {
                        group: list.split("List")[0],
                        categories: this[list],
                    },
                ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "domain", [...this.domainList, ...this.nameList], data);
            allChecked = [...this.domainList, ...this.nameList]?.length ? ([...this.domainList, ...this.nameList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        setInput() {
            this.searchKeyword = this.selectedItem?.value ?? "";
        },

        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
        },

        onSelect({value}) {
            console.log(value);
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.searchKeyword = this.selectVal;
            this.selectedItem = {
                value: this.selectVal,
            };
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchedClicked = false;
            }
            this.selectVal = event;
        },

        onSelectFieldType() {
            this.showResult = false;
            this.selectedItem = null;
            this.selectVal = "";
            this.$refs["analysis-tool-input"].search = "";
        },

        updateFieldType() {
            this.selectedItem = null;
            this.valueInput = "";
        },

        async setSearchedUser(searchKeyword) {
            let val_type = "";
            if (this.inputValue.value === "name") {
                val_type = "name";
            } else if (this.inputValue.value === "domain") {
                val_type = "domain";
            }
            const data = {
                value: searchKeyword,
                platform: "domain",
                val_type: val_type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let listName = val_type !== "domain" ? "nameList" : "domainList";
            let dataItem = this[listName].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[listName].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        getJurisdictionDisplayName(code) {
            return getJurisdictionName(code);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },
        /**
         * Fired when search queries are clicked && monitoring tab is active
         * @params {String} data: data state
         */
        async monitoringSearch(data) {
            this.startLoader();

            let values = await monitoringSearchData(this.case_id, null, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);

            this[data] = values;

            this.resetLoader();
        },

        async getDomainDetails() {
            if (this.isMonitoringTab) {
                await this.monitoringSearch("monitoringDomainData");
                return;
            }
            this.startLoader();
            let requestBody = {
                domain: this.searchKeyword,
            };

            const response = await axios.post("/domain/registration/lookup", requestBody, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                },
            });
            try {
                if (response && response.data && response.data.data) {
                    this.domainData = response.data.data;
                    this.graphData = [{company: {name: this.searchKeyword}}];
                    if (this.graphData) {
                        this.graphData[0].company = {...this.graphData[0].company, domainData: this.domainData};
                        // this.graphData[0].company = { ...this.graphData[0].company, registrant: this.domainData.registrant};
                        // this.graphData[0].company = { ...this.graphData[0].company, technicalContact: this.domainData.technicalContact};
                        this.$emit("searchResult", {data: this.graphData, source: "domain", searchType: "domain"});
                    }
                    const years = Math.floor(this.domainData.estimatedDomainAge / 365);
                    const months = Math.floor((this.domainData.estimatedDomainAge - years * 365) / 30);
                    const days = Math.round(this.domainData.estimatedDomainAge - years * 365 - months * 30);
                    let res = [];
                    if (years > 0) {
                        res.push(years + " years");
                    }
                    if (months > 0) {
                        res.push(months + " months");
                    }
                    if (days > 0) {
                        res.push(days + " days");
                    }

                    this.estimatedDomainAge = res.join(", ");
                } else if (response && response.data && response.data.message) {
                    let message = response.data.message;
                    this.$toast.error(`${message}`);
                } else if (response) {
                    this.$toast.error("No data found");
                }
                this.request_id = response?.data?.query_id ? response?.data?.query_id : response?.data?.request_id;
                this.redirectTo();
                if (this.request_id) {
                    await this.getDomainCompanyDetails();
                    await this.getDomainLinkedInUserDetails();
                    await this.setSearchedUser(this.searchKeyword);
                }
            } catch (error) {
                this.domainData = {};
            }
            this.showResult = true;
            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "company-domain"});
            this.resetLoader();
        },
        async getDomainCompanyDetails() {
            if (this.isMonitoringTab) {
                await this.monitoringSearch("monitoringDomainCompanyDetails");
                return;
            }
            this.startLoader();
            let requestBody = {
                domain: this.searchKeyword,
            };

            // this.$emit("searched", { data: { domain: this.searchKeyword }, source: "domain" });

            const response = await axios.post("/domain/company", requestBody, {
                headers: {
                    "x-query-id": this.request_id,
                    "x-tool-name": this.$route.params.toolName,
                },
            });
            try {
                if (response && response.data && response.data.data) {
                    this.domainCompanyDetails = response.data.data;
                    this.graphData = [{company: {name: this.domainCompanyDetails.name ?? this.searchKeyword}}];
                    // Calling company details Search
                    this.$emit("searchResult", {data: this.graphData, source: "domain", searchType: "company"});
                    await this.getDomainCompanyDetailsSearch(this.domainCompanyDetails.name);
                } else if (response && response.data && response.data.message) {
                    let message = response.data.message;
                    this.$toast.error(`${message}`);
                } else if (response) {
                    this.$toast.error("No data found");
                }
            } catch (error) {
                this.domainCompanyDetails = null;
            }
            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "company-domain"});
            this.resetLoader();
        },
        async getDomainLinkedInUserDetails() {
            if (this.isMonitoringTab) {
                await this.monitoringSearch("domainLinkedInUserDetails");
                return;
            }
            this.startLoader();
            let requestBody = {
                domain: this.searchKeyword,
            };
            const response = await axios.post("/linkedin/search/domain", requestBody, {
                headers: {
                    "x-query-id": this.request_id,
                    "x-tool-name": this.$route.params.toolName,
                },
            });
            try {
                if (response && response.data && response.data.data) {
                    this.domainLinkedInUserDetails = response.data.data;
                    if (this.graphData) {
                        this.graphData[0].company = {...this.graphData[0].company, employees: response.data.data};
                        this.$emit("searchResult", {data: this.graphData, source: "domain", searchType: "company"});
                    }
                } else if (response && response.data && response.data.message) {
                    let message = response.data.message;
                    this.$toast.error(`${message}`);
                } else if (response) {
                    this.$toast.error("No data found");
                }
            } catch (error) {
                this.domainLinkedInUserDetails = [];
            }

            this.resetLoader();
            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "company-domain"});
        },
        async getDomainCompanyDetailsSearch(companyName) {
            this.startLoader();
            let requestBody = {
                name: companyName,
                request_id: this.request_id,
            };
            const response = await axios.post("/company/search", requestBody, {
                headers: {
                    "x-query-id": this.request_id,
                    "x-tool-name": this.$route.params.toolName,
                },
            });
            try {
                if (response && response.data && response.data.data) {
                    this.domainCompanyDetailsSearch = response.data.data;
                    // this.$emit("searchResult", { data: this.domainCompanyDetailsSearch, source: "domain", searchType: "company" });
                    this.graphData[0].company = {...this.graphData[0].company, companies: response.data.data};
                    this.$emit("searchResult", {data: this.graphData, source: "domain", searchType: "company"});
                } else if (response && response.data && response.data.message) {
                    let message = response.data.message;
                    this.$toast.error(`${message}`);
                }
            } catch (error) {
                this.domainCompanyDetailsSearch = [];
            }
            this.resetLoader();
            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "company-domain"});
        },
        setLocationOnMap() {
            let address = this.domainCompanyDetails?.location;
            // setting location on map
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({address: address}, function(results, status) {
                if (results?.length > 0) {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();
                    const center = {lat: lat, lng: lng};
                    const map = new google.maps.Map(document.getElementById("googleMap"), {
                        zoom: 12,
                        center: center,
                    });
                    // The marker, positioned at center
                    const marker = new google.maps.Marker({
                        position: center,
                        map: map,
                    });
                }
            });
        },
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },

        async search(domainname) {
            this.triggerRoute = false;
            this.searchedClicked = true;
            this.request_id = null;

            if (domainname) {
                this.searchKeyword = domainname;
                (this.inputValue = {
                    text: "Domain",
                    value: "domain",
                }),
                    (this.selectedItem = {
                        value: domainname,
                    });
                this.selectVal = domainname;
                this.$refs["analysis-tool-input"].search = domainname;
            }
            if (this.searchKeyword) {
                this.showResult = false;
                this.domainData = null;
                this.domainCompanyDetails = null;
                this.domainLinkedInUserDetails = [];
                this.domainCompanyDetailsSearch = [];
                this.domainNameResults = [];
                this.selectedTab = "domain";
                if (this.inputValue.value == "domain" || domainname) {
                    this.$emit("searched", {data: {domain: this.searchKeyword}, source: "domain"});
                    await this.getDomainDetails();
                } else if (this.inputValue.value == "name") {
                    this.startLoader();
                    this.$emit("searched", {data: {company_name: this.searchKeyword}, source: "domain"});
                    let requestBody = {
                        query: this.searchKeyword,
                    };

                    const response = await axios.post("/domain/registration/domain-name", requestBody, {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-visited": true,
                        },
                    });
                    if (response.data.data) {
                        this.domainNameResults = response.data.data;
                        this.showDomainResult = true;
                        this.request_id = response?.data?.query_id;
                        this.redirectTo();
                    } else if (response.data && response.data.message) {
                        let message = response.data.message;
                        this.$toast.error(`${message}`);
                    }
                    this.resetLoader();
                    await this.setSearchedUser(this.searchKeyword);
                }
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "company-domain"});
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];

                this.searchKeyword = value;
                this.selectedItem = {
                    value: value,
                };
                this.selectVal = value;
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
    },
};
